<template>
  <div class="bg-black flex justify-between items-center h-80 p-0">
    <picture>
      <source
        type="image/webp"
        media="(max-width: 1023px)"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-1-s.svg', {
            height: 80,
          })},
          ${$img('/assets/img/gg-shiver/ghw-1-s.svg', {
            height: 80,
          })} 2x
        `"
      />
      <source
        type="image/webp"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-1.png', {
            height: 80,
          })},
          ${$img('/assets/img/gg-shiver/ghw-1.png', {
            height: 80,
          })} 2x
        `"
      />
      <img
        alt=""
        height="80"
        style="height: 80px"
        :src="$img('/assets/img/gg-shiver/ghw-1.png', { width: 97 })"
      />
    </picture>
    <picture style="padding: 0px 15px">
      <source
        type="image/webp"
        media="(max-width: 1023px)"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-2.svg', {
            width: 218,
            // format: 'webp',
          })},
          ${$img('/assets/img/gg-shiver/ghw-2.svg', {
            width: 436,
            // format: 'webp',
          })} 2x
        `"
      />
      <source
        type="image/webp"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-2-lg.svg', {
            width: 700,
            // format: 'webp',
          })},
          ${$img('/assets/img/gg-shiver/ghw-2-lg.svg', {
            width: 1400,
            // format: 'webp',
          })} 2x
        `"
      />
      <source
        type="image/png"
        :srcset="$img('/assets/img/gg-shiver/ghw-2-lg.svg', { width: 700 })"
      />
      <img
        alt=""
        width="218"
        height="80"
        style="height: auto; min-height: 40px"
        :src="$img('/assets/img/gg-shiver/ghw-2.svg', { width: 218 })"
      />
    </picture>
    <picture>
      <!-- <source
        type="image/webp"
        :srcset="`
          ${$img('/assets/img/gg-shiver/ghw-3.png', {
            width: 40,
            format: 'webp',
          })},
          ${$img('/assets/img/gg-shiver/ghw-3.png', {
            width: 80,
            format: 'webp',
          })} 2x
        `"
      />
      <img
        alt=""
        width="40"
        height="40"
        :src="$img('/assets/img/gg-shiver/ghw-3.png', { width: 40 })"
      /> -->
    </picture>
  </div>
</template>
